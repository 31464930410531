import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
import "./index.css";
import { MoralisProvider } from "react-moralis";
import WalletProvider from "./contexts/connection";

const {
  REACT_APP_MORALIS_SERVER_URL,
  REACT_APP_MORALIS_APP_ID
} = process.env;

ReactDOM.render(
  <MoralisProvider serverUrl={REACT_APP_MORALIS_SERVER_URL} appId={REACT_APP_MORALIS_APP_ID}>
    <WalletProvider>
      <App />
    </WalletProvider>
  </MoralisProvider>, 
  document.getElementById("body")
);
