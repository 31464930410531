import React, {useState} from "react";
import DripReverse from "../DripReverse";
import styles from "./Page.module.sass";
import Header from "../Header";
import Footer from "../Footer";
import Navbar from "../Header/Navbar";

const Page = ({ children }) => {
  const [navbar, setNavbar] = useState(false);
  return (
    <div className={styles.page}>
    <Navbar
      navbar={navbar}
      setNavbar={(navbar) => setNavbar(navbar)}
    />
    <div className={`${navbar && "hidden"} mt-24 lg:mt-0`}>
      <Header />
      {/* 
      <DripPurple /> */}
      <div className={styles.inner}>{children}</div>
      <DripReverse />
      <Footer /></div>
    </div>
  );
};

export default Page;
