import React from "react";
import styles from "./Header.module.sass";
import ReactPlayer from "react-player";
import video from "./../../assets/media/MONKAI_ANIM_2.mp4";
import DripPurple from "../DripPurple";
const Headers = () => {

  return (
    <header className={styles.header}>
      <ReactPlayer
        className="react-player"
        url={video}
        width="100%"
        height=""
        playing={true}
        volume={0}
        loop={true}
        playsinline={true}
        muted={true}
      />
      <DripPurple />
    </header>
  );
};

export default Headers;
