import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cookies from "universal-cookie";
import NotFound from "./pages/NotFound";
import Mint from "./pages/Mint";
import Page from "./components/Page";
import * as anchor from "@project-serum/anchor";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
  getTorusWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
require("@solana/wallet-adapter-react-ui/styles.css");

const getCandyMachineId = () => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_PUBLIC_CANDY_MACHINE_ID
    );

    return candyMachineId;
  } catch (e) {
    console.log("Failed to construct CandyMachineId", e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_PUBLIC_SOLANA_NETWORK;
const rpcHost = process.env.REACT_APP_PUBLIC_SOLANA_RPC_HOST;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl("devnet")
);

const txTimeoutInMilliseconds = 30000;

const App = () => {
  const wallets = [
    getPhantomWallet(),
    getSlopeWallet(),
    getSolflareWallet(),
    getTorusWallet({
      options: {
        clientId: "CLIENT_ID",
      },
    }),
    getLedgerWallet(),
    getSolletWallet({ network }),
    getSolletExtensionWallet({ network }),
  ];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const solanaReferrer = params.get("solana");
    const ethereumReferrer = params.get("ethereum");

    const cookies = new Cookies();

    if (solanaReferrer) {
      cookies.set("solanaReferrer", solanaReferrer, {
        path: "/",
        maxAge: 60 * 60 * 24 * 7,
      });
    }

    if (ethereumReferrer) {
      cookies.set("ethereumReferrer", ethereumReferrer, {
        path: "/",
        maxAge: 60 * 60 * 24 * 7,
      });
    }
  }, []);

  return (
    <ConnectionProvider endpoint={rpcHost}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <Page>
                    <Mint
                      candyMachineId={candyMachineId}
                      connection={connection}
                      txTimeout={txTimeoutInMilliseconds}
                      rpcHost={rpcHost}
                    />
                  </Page>
                }
              ></Route>

              <Route element={NotFound} />
            </Routes>
          </BrowserRouter>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
