import React from "react";
import { Logo } from '../Logo';
import Navlink from '../Navlink/Navlink';
import NavLinkMobile from '../Navlink/NavlinkMobile';
import cn from 'classnames';
import styles from './Navbar.module.css';


const navLinks = [
  {
    name: 'stake',
    path: 'https://monkainft.com/stake',
    title: 'STAKING',
  },/* 
  {
    name: 'whitelist',
    path: '/whitelist',
    title: 'WHITELIST',
  }, */
  {
    name: 'roadmap',
    path: 'https://monkainft.com/roadmap',
    title: 'ROADMAP',
  },
  {
    name: 'whitepaper',
    path: 'https://monkainft.com/whitepaper',
    title: 'WHITEPAPER',
  },
//  {
//    name: 'mint sol',
//    path: 'https://opensea.io/collection/monkai/mint',
//    title: 'BUY SOL',
//    mobile: true,
//  },
  {
    name: 'minteth',
    path: 'https://mint.monkainft.com',
    title: 'MINT ETH',
    mobile: true,
  },
  // {
  //   name: 'market',
  //   path: '/market',
  //   title: 'MARKET',
  // },
];

export default function Navbar({ navbar, setNavbar }) {
  const handleNavbar = () => {
    setNavbar(!navbar);
  };

  return (
      <div className={cn(styles.navbarContainer)}>
        <div className={styles.navbar}>
          <div className={styles.logoContainer}>
            <div className={styles.logo} onClick={() => navbar && handleNavbar()}>
              <Logo />
            </div>
          </div>
          <div className={styles.itemsDesktop}>
            <ul className={styles.itemsListDesktop}>
              {navLinks.map((navLink) => (
                <li key={navLink.name} className={navLink.mobile ? styles.mobile : styles.desktop}>
                  <Navlink {...navLink} />
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.register}>
            <a href={'https://monkainft.com/roadmap'}>
              <button>Roadmap</button>
            </a>
            <a href={'https://mint.monkainft.com'}>
              <button>Mint ETH</button>
            </a>
          </div>
          <div className={styles.itemsMobile} onClick={() => handleNavbar()}>
            <div className={styles.hamburgerLines}>
              <span className={cn(styles.line, styles.line1, navbar && styles.checked)}></span>
              <span className={cn(styles.line, styles.line2, navbar && styles.checked)}></span>
              <span className={cn(styles.line, styles.line3, navbar && styles.checked)}></span>
            </div>
          </div>
          <div className={cn(styles.hamburgerMenu, !navbar && styles.hidden)} onClick={() => handleNavbar()}>
            <ul className={cn(styles.navMobile, 'flex', 'absolute', 'top-24', 'right-0', 'bottom-0', 'w-full', 'z-50', 'flex-col', navbar ? styles.fromLeft : styles.left)}>
              {navLinks.map((navLink) => (
                <li key={navLink.name}>
                  <NavLinkMobile {...navLink} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
  );
};
