import React from "react";
import styles from "./Footer.module.sass";

const Footers = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.picContainer}>
        <h2 className={styles.logo}>MONKAI</h2>
      </div>
    </footer>
  );
};

export default Footers;
